import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ClickClickVideo from "../../content/click-click.mp4"

const About = () => (
  <Layout>
    <Seo title="About" />
    <h1>About</h1>    
    <p>Use this page to decide which rp23 sessions you are interested in. This should help you to plan ahead and follow up later.</p>
    <video controls width="750" height="720">
      <source src={ClickClickVideo} type="video/mp4" />
    </video>
    <p>
      This is how it works.
    </p>    
    <p>Revisit the site to see new sessions and your current schedule. They are updated frequently, before and during the event as well.</p>
    <p></p>
    <p>Your selection is saved in this browser.</p>    
    <p>Transfer your <Link to="/sessionlist?status=1">'Yes'-sessions</Link> to the <a href="https://apps.apple.com/de/app/republica/id869014061">rp22 iOS conference app of toto</a> - they appear as favs there (use link on start page). Not sure if the brand new (June 6th) official app also supports this, I'm about to find out.</p>
    <p>Use <Link to="/share">Share</Link> to name your selection and load it on any other device.</p>
    <p></p>
      <h2>Remarks</h2>
        <ul>
            <li>Just for fun private project (and to learn and try Gatsby and Azure Cosmos database).</li>            
            <li>Is not an official re:publica offering but OK for them.</li>
            <li>Stores your selection locally in your browser storage. F12 -> Application -> Local Storage</li>
            <li>Saved lists and selections are stored in a database with no telemetry or personal data of you and your visits combined.</li>
            <li>Site saves no user activities and stores only two cookies to analyse total server activity (page calls, unique visitors, region) using Microsoft Application Insight. No combination of visitor analytics and saved lists is performed. Our hoster (Hetzner) stores the logfiles of the webserver. No additional third parties involved.</li>
            <li>Data stored by Microsoft is stored on their German servers.</li>
            <li>Questions, interested in the code? Contact Andreas @rosenthaltech</li>
            <li><a href="https://andreasrosenthal.com/">That's me</a></li>
        </ul>
    
    <h2>#todo</h2>
    <ul>
      <li>Conference Mode: filter sessions in the past</li>
      <li>Group status list by day</li>
      <li>Browse: Keyboard shortcuts</li>
      <li>Browse: Undo</li>
    </ul>
    <h2>rp23</h2>
    This site was used by 335 distinct users in the timeframe June 3 - June 13 2022. This seems to be at least the proof of an interest for such tools.
    <p></p>
    Although the intention is to prepare and follow up, some users used it during the event as well, which was not a perfect experience on day two and three. For the usage during rp23 I'll enhance this usability.
    <p></p>
    If you have feedback or want to contribute, please contact me.
    <p></p>
    Version&nbsp;<code>2023-06-06 11:36</code>
  </Layout>
)

export default About